import Axios from 'axios';

export const API_HOST = process.env.REACT_APP_API_HOST;

const API_PREFIX = '/api/v1';

const axios = Axios.create({
	baseURL: `${API_HOST}`,
	withCredentials: false,
	headers: {
		'Content-Type': 'application/json',
	},
});

axios.interceptors.request.use(
	(config) => {
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

axios.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		return Promise.reject(error);
	},
);

export { axios as default, API_PREFIX };
