import { FC } from 'react';

import { Button, Flex, Typography } from 'antd';
import { ReactComponent as MailIcon } from 'assets/svg/mail.svg';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { sendSessionId } from 'shared/api/subscription.service';

import styles from './Success.module.scss';

const { Title, Text } = Typography;

const Success: FC = (): JSX.Element => {
	const [searchParams] = useSearchParams();
	const sessionId = searchParams.get('session_id');

	useQuery({ queryFn: () => sendSessionId(sessionId), enabled: !!sessionId });

	return (
		<section className={styles.container}>
			<Flex vertical gap={24} align="center" justify="center" className={styles.card}>
				<Title className={styles.title}>Thank you for choosing Corvus Link!</Title>
				<Text className={styles.text}>
					Your account will be set up within the next hour and you will receive an email to activate
					it.
				</Text>
				<MailIcon />
				<a style={{ width: '100%' }} href="https://www.corvuslink.com">
					<Button type="primary" style={{ width: '100%' }}>
						Back to Corvus Link
					</Button>
				</a>
			</Flex>
		</section>
	);
};

export default Success;
