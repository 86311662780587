import { FC, ReactNode } from 'react';

import { Button, Col, Flex, Form, Input, Row, Typography } from 'antd';
import classNames from 'classnames';
import FormContainer from 'shared/ui/FormContainer';

import styles from './RegistrationPlanFormStepOne.module.scss';

const { Title, Text } = Typography;

type TRegistrationPlanFormStepOneProps = {
	children: ReactNode;
};

const RegistrationPlanFormStepOne: FC<TRegistrationPlanFormStepOneProps> = ({
	children,
}): JSX.Element => {
	return (
		<>
			<FormContainer position="left">
				<Flex vertical>
					<Title className={styles.title}>Try free for 2 weeks</Title>
					<Text className={classNames(styles.text, styles.text36)}>
						Then just <span className={styles.textThrough}>$60/month</span>{' '}
						<span className={styles.textBold}>$15/month with the annual plan</span>
					</Text>
					<Text className={styles.text}>🔥 (Regular price: $60/month)</Text>
					<Text className={styles.text}>
						🚀 Save 75% on our AI Consulting Tool with AI Voice Chat
					</Text>
					<Text className={classNames(styles.text, styles.text18)}>
						⏰ Only available for the first 250 subscribers
					</Text>
					<Text className={classNames(styles.text, styles.text18, styles.textSmall)}>
						Transform your workflow and boost productivity with Corvus Link. Join the innovation
						leaders today and experience the difference.
					</Text>
					<Text className={classNames(styles.text, styles.textSmall)}>
						Ready to elevate your game?{' '}
						<span className={styles.textSmallBold}>Sign up now and save big!</span>
					</Text>
				</Flex>
			</FormContainer>
			<FormContainer position="right">
				<Title className={classNames(styles.title, styles.titleRight)}>
					Sign Up Now and Save Big!
				</Title>

				<Row gutter={[16, 16]}>
					<Col xl={12} lg={12} xs={24}>
						<Form.Item
							label="First Name"
							name="firstName"
							rules={[{ required: true, message: 'Required field', whitespace: true }]}
						>
							<Input placeholder="Enter First Name" />
						</Form.Item>
					</Col>
					<Col xl={12} lg={12} xs={24}>
						<Form.Item
							label="Last Name"
							name="lastName"
							rules={[{ required: true, message: 'Required field', whitespace: true }]}
						>
							<Input placeholder="Enter Last Name" />
						</Form.Item>
					</Col>
				</Row>

				<Form.Item
					label="Email"
					name="email"
					rules={[{ required: true, message: 'Required field', whitespace: true }]}
				>
					<Input type="email" placeholder="Enter Email" />
				</Form.Item>
				<Button className={styles.submit} type="primary" form="registration-form" htmlType="submit">
					Next
				</Button>
				{children}
			</FormContainer>
		</>
	);
};

export default RegistrationPlanFormStepOne;
