export const antdThemeStyles = {
	colorPrimary: '#46ABFF',
	colorPrimaryHover: '#60B7FF',
	colorPrimaryActive: '#0080EA',
	colorInfo: '#3498DB',
	colorInfoHover: '#3498DB',
	colorError: '#D03900',
	colorErrorHover: 'rgba(208, 57, 0, 0.6)',
	colorErrorActive: '#9A2A00',
	colorSuccess: '#46ABFF',
	fontFamily: `'Jost', sans-serif`,
	colorText: '#1A2026',
	colorTextSecondary: '#1A2026',
	colorTextDisabled: '#BFBFBF',
	controlHeight: 40,
	fontWeightStrong: 600,
	Typography: {
		fontSizeHeading1: 32,
		lineHeightHeading1: 0.6666,
		titleMarginBottom: 0,
	},
	Button: {
		contentFontSize: 16,
		fontWeight: 600,
	},
};
