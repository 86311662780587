import { FC } from 'react';

import Success from 'widgets/Success';

const SuccessPage: FC = (): JSX.Element => {
	return (
		<>
			<Success />
		</>
	);
};

export default SuccessPage;
