import axios, { API_PREFIX } from 'shared/configs/axios/axios';

const CONTROLLER_PREFIX = 'subscriptions';

export type TAddSubscriptionDetailsPayload = {
	numberOfLicenses: number;
	domainPrefix: string;
	customer: {
		firstName: string;
		lastName: string;
		email: string;
	};
};

export type TAddSubscriptionDetailsResponse = {
	id: string;
	amount: number;
	paymentUrl: string;
};

export const addSubscriptionsDetails = async (
	payload: TAddSubscriptionDetailsPayload,
): Promise<TAddSubscriptionDetailsResponse> => {
	const { data } = await axios.post<TAddSubscriptionDetailsResponse>(
		`${API_PREFIX}/${CONTROLLER_PREFIX}`,
		payload,
	);
	return data;
};

export const sendSessionId = async (stripeSessionId: string): Promise<void> => {
	await axios.post<void>(
		`${API_PREFIX}/${CONTROLLER_PREFIX}/finalize`,
		{},
		{ params: { stripeSessionId } },
	);
};
