import { Dispatch, FC, SetStateAction } from 'react';

import { Button, Flex, Form, Input, InputNumber, Typography } from 'antd';
import { ReactComponent as CheckIcon } from 'assets/svg/checkList.svg';
import classNames from 'classnames';
import FormContainer from 'shared/ui/FormContainer/FormContainer';

import styles from './RegistrationPlanFormStepTwo.module.scss';

const { Title, Text, Paragraph } = Typography;

type TRegistrationPlanFormStepTwoProps = {
	isLoadingAddSubscriptionsDetails: boolean;
	setFormStep: Dispatch<SetStateAction<number>>;
};

const RegistrationPlanFormStepTwo: FC<TRegistrationPlanFormStepTwoProps> = ({
	isLoadingAddSubscriptionsDetails,
	setFormStep,
}): JSX.Element => {
	const validateDomain = (_, value): any => {
		if (value) {
			const regex = /^[a-zA-Z0-9]+([-]{1}[a-zA-Z0-9]+)*(\.[a-zA-Z0-9]+([-]{1}[a-zA-Z0-9]+)*)*$/;

			if (!regex.test(value)) {
				return Promise.reject('Invalid domain prefix');
			}
		}
		return Promise.resolve();
	};

	const validateNumberOfLicenses = (_, value): any => {
		if (value > 99) {
			return Promise.reject('Maximum Number of Licenses is 99');
		}
		return Promise.resolve();
	};
	return (
		<>
			<FormContainer position="left">
				<Flex vertical>
					<Title className={styles.title}>
						Empower collaboration for just $3 per person month!
					</Title>
					<Text className={classNames(styles.text, styles.text36)}>
						1 License plus 5 collaborators for just $15/month
					</Text>
					<Text className={styles.text}>🚀 Limited Offer: $15/month with annual plan</Text>
					<Text className={classNames(styles.text, styles.text18)}>
						🌐 FREE custom domain for faster, easier collaboration
					</Text>
					<Text className={classNames(styles.text, styles.text18, styles.textSmall)}>
						Sign-up now because it is only available for the first 250 subscribers! Regular price:
						$60/month
					</Text>
					<Text className={classNames(styles.text, styles.text12)}>Perfect for:</Text>
					<Paragraph className={classNames(styles.text, styles.listItem)}>
						<Flex align="center" gap={8}>
							<CheckIcon />
							Strategic Consultancies
						</Flex>
					</Paragraph>
					<Paragraph className={classNames(styles.text, styles.listItem)}>
						<Flex align="center" gap={8}>
							<CheckIcon />
							Startups
						</Flex>
					</Paragraph>
					<Paragraph className={classNames(styles.text, styles.listItem)}>
						<Flex align="center" gap={8}>
							<CheckIcon />
							Small businesses
						</Flex>
					</Paragraph>
					<Paragraph className={classNames(styles.text, styles.listItem)}>
						<Flex align="center" gap={8}>
							<CheckIcon />
							Project teams
						</Flex>
					</Paragraph>
				</Flex>
			</FormContainer>
			<FormContainer position="right">
				<Title className={classNames(styles.title, styles.titleRight)}>
					Supercharge Your Team&apos;s Productivity
				</Title>
				<Form.Item
					label="Number of Licenses Needed"
					name="numberOfLicenses"
					rules={[
						{ required: true, message: 'Required field' },
						{ validator: validateNumberOfLicenses, message: 'Maximum Number of Licenses is 99' },
					]}
				>
					<InputNumber
						type="number"
						min={1}
						placeholder="Enter Number of Licenses"
						style={{ width: '100%' }}
					/>
				</Form.Item>
				<p className={styles.formItemLabel}>Desired Domain Prefix</p>
				<Text>
					Provide the domain prefix (<strong>________.corvuslink.com</strong>) you would like.
				</Text>
				<Form.Item
					name="domainPrefix"
					rules={[
						{ required: true, message: 'Required field' },
						{ validator: validateDomain, message: 'Invalid domain prefix' },
					]}
				>
					<Input
						maxLength={24}
						placeholder="Enter your desired domain prefix, for example: secretsauce.corvuslink.com"
					/>
				</Form.Item>
				<Flex gap={24}>
					<Button
						loading={isLoadingAddSubscriptionsDetails}
						className={styles.submit}
						onClick={(): void => setFormStep(0)}
					>
						Back
					</Button>
					<Button
						loading={isLoadingAddSubscriptionsDetails}
						className={styles.submit}
						type="primary"
						form="registration-form"
						htmlType="submit"
					>
						Next
					</Button>
				</Flex>
			</FormContainer>
		</>
	);
};

export default RegistrationPlanFormStepTwo;
