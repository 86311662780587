import { FC, ReactNode } from 'react';

import classNames from 'classnames';

import styles from './FormContainer.module.scss';

type TFormContainerProps = {
	children: ReactNode;
	position: 'left' | 'right';
};

const FormContainer: FC<TFormContainerProps> = ({ children, position }): JSX.Element => {
	return <div className={classNames(styles.container, styles[position])}>{children}</div>;
};

export default FormContainer;
