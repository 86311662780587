import { FC } from 'react';

import { ReactComponent as MainLogoIcon } from 'assets/svg/corvusLogoMain.svg';

import styles from './AppHeader.module.scss';

const AppHeader: FC = (): JSX.Element => {
	return (
		<header className={styles.logoContainer}>
			<MainLogoIcon />
		</header>
	);
};

export default AppHeader;
